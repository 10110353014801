@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Manrope";
}

code {
  font-family: "Manrope";
}
